// Replay Modal
.drawingReply {


  // overrides
  .overlay-conversation-modal {
    position: static;
    background-color: rgba(255, 255, 255, 0.9);

    //background-color: transparent;
    .overlay-modal-in {
      position: static;

      .content-base {
        min-height: 0;
        position: relative;
        flex-shrink: 0;
        flex-grow: 1;
        flex-basis: 800px;
        max-width: 800px;
      }
    }

    .brush_size_slider {
      left: 130px;
      top: auto;
      bottom: 45px;

      // transform: rotate(90deg);
      &_in {
        .close_slider {
          &:after {
            content: "x";
          }
        }
      }
    }

    .main-alpha-tool {
      transform: translate(-30px, -50%);
    }
  }

  .modalConversationSketchpad {
    // .toolbox-area {
    //   top: auto;
    //   left: 0;
    //   right: 0;
    //   bottom: -80px;
    //   margin: auto;
    //   width: 510px !important;
    //   height: 155px;
    //   position: absolute;
    //   z-index: 1250;
    //   .toolbox-area-in {
    //     .toolbox-cover {
    //       top: 0 !important;
    //       .toolbox {
    //         .tool-icons {
    //           ul{
    //             top: 0;
    //           }
    //         }
    //         .clear-button{
    //           margin-left: -2px !important;
    //           bottom: -3px;
    //         }
    //         .color-picker{
    //           transform: translate(-30px, -50%);
    //           margin-top: 5px;
    //         }
    //       }
    //       .save-button-toolbox{
    //         width: 73px;
    //         height: 54px;
    //         top: 65px;
    //       }
    //     }
    //   }

    //   &.toolbar-hor {
    //     .sp-light {
    //       position: absolute;
    //       border: none;
    //       background: url(/static/media/arrow-top.12538435.svg);
    //         background-repeat: repeat;
    //         background-size: contain;
    //       width: 65%;
    //       height: 65%;

    //       padding: 3px;
    //     }
    //   }

    // }


    .toolbox-area {
      left: 0;
      bottom: 0;
      position: absolute;
      transform: rotate(-90deg) !important;
      right: 0;
      background: transparent;
      z-index: 1500;
    }

    .toolbar-horz {
      .kit-logo-h {

        //transform: rotate(0.75turn);
        &::before {
          content: "";
          background: url('../../assets/images/LogoVertical.svg') no-repeat center center;
          background-size: auto;
          background-size: contain;
          position: absolute;
          width: 100%;
          height: 110px;
          top: -30px;
          z-index: 2;
          left: 0;
          right: auto;
          transform: rotate(0.25turn);
        }
      }
    }

    .interactionBox {
      .sketchpad-box {
        position: relative;
        height: 602px;
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .scrollarea {
    .railarea {
      // justify-content: center; not working as expected when multiple images appeared
      justify-content: initial;
      width: initial;
      overflow-x: auto;

      // Hacky Solution for center Elements (justify-content: center;)
      .canvasImages {
        margin-left: auto;
      }

      .drawingCanvasControlPanel {
        margin-right: auto;
        flex-grow: 1;
        flex-basis: auto;
        max-width: 300px;
        flex-shrink: 0;
      }
    }
  }

  .toolbox {
    .tool-icons {
      margin-top: 0;
      padding-top: 0;
      top: 30px;
      position: relative;

      ul {
        li {
          &.pencil_li {
            left: 8px;
            top: auto;
            bottom: 10px;

            .pencil {
              transform: rotate(10deg);
              transform: rotate(15deg);
              width: 70px !important;
              height: 22px !important;
              left: 0;
              top: 0;
            }
          }

          &.round_brush_li {
            left: 40px;
            top: 6px;

            .round_brush {
              transform: rotate(15deg);
              width: 95px !important;
              height: 25px !important;
              left: 0;
              top: 0;

            }
          }

          &.flat_brush_li {
            left: 40px;
            top: 6px;

            .flat_brush {
              transform: rotate(25deg);
              width: 100px !important;
              height: 28px !important;
              left: 0;
              top: 0;

            }
          }

          &.marker_li {
            left: 57px;

            .marker {
              transform: rotate(0deg);
              width: 60px !important;
              height: 22px !important;
              left: 0;
              top: 0;
            }
          }

          &.eraser_li {
            left: 6px;
            top: -3px;

            .eraser {
              transform: rotate(90deg);
              width: 48px;
              height: 32px;
              left: 0;
              top: 0;
            }
          }

          &.go_forward_li {
            left: 45px;
            top: -12px;

            .goForward {
              transform: rotate(90deg);
              left: 0;
              top: 0;
            }
          }

          &.go_back_li {
            top: 30px;
            left: 12px;

            .goBack {
              transform: rotate(90deg);
              left: 0;
              top: 0;
            }
          }

        }
      }
    }


    .clear-button {
      top: auto;
      left: 22px;
      width: 20px;
      bottom: 50px;

      .btn-reset {
        .toTrash {
          transform: rotate(90deg);
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          position: relative;
        }
      }
    }
  }

  // Custom Styling
  .canvasImages {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;

    >img,
    >canvas {
      height: 600px;
    }
  }

  .drawingCanvasControlPanel {
    padding: 0 20px;
  }

  .toolkit-hor {
    z-index: 2000;
    position: relative;

    .marker_slider_in {
      ul {
        left: 25px;
        text-align: center;
        top: auto;
      }
    }

    .round_slider_in {
      ul {
        left: 50px;
        text-align: center;
        top: auto;
        bottom: -50px;
      }
    }

    .flat_slider_in {
      ul {
        left: 40px;
        text-align: center;
        top: -8px;
        bottom: 0;
      }
    }

    .eraser_slider_in {
      ul {
        left: 5px;
        text-align: center;
        top: -15px;
      }
    }

  }


  ul {
    #brush-size-list {
      -webkit-transform: rotate(90deg) !important;
      -ms-transform: rotate(90deg) !important;
      transform: rotate(90deg) !important;

      .marker_slider_in {
        ul {
          left: 25px;
          text-align: center;
          top: auto;
        }
      }

      .eraser_slider_in {
        ul {
          &.index-list {
            transform: rotate(90deg) scaleX(-1);
          }
        }
      }


      li {
        float: none;
      }

      &.flip-slider {
        transform: rotate(90deg) scaleX(-1) !important;
      }

    }
  }

  .toolbar-horz {
    .toolbox.custom-color {
      background: url('../DrawingCanvas/images/toolkit-1.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }

}

.iroWheelCanvasPicker:hover {
  cursor: url(/static/media/picker02.9a8e064e.svg) 0 74, auto !important;
}

.iroWheelCanvas:hover {
  cursor: url(/static/media/picker02.9a8e064e.svg) 0 74, auto;
}

#mouse-circle {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  left: -100px;
  top: 50%;
 // mix-blend-mode: difference; //for dark and light modes
  background-color: transparent;
  z-index: 10000;
  border: 1px solid white;
  height: 30px;
  width: 30px;
}

.color-shortcut {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  padding: 0 20px;
  top: 186px;
  z-index: 9999;
  
  &.color-shortcut-bg {
    top: 200px;
  }
  .color-button {
    height: 20px;
    width: 20px;
    border: 2px solid grey;
    border-radius: 50%;

    &.black {
      background-color: black;
    }

    &.white {
      background-color: white;
    }
  }
}

.colorpick-container {
  position: relative;
  width: 100%;
  height: 100%;
}