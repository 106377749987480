@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";
@import "../../assets/styles/components/modals";

.drawingCanvas {
  .drawingCanvasPaper {
    background-color: transparent;
  }

  .drawingCanvasContent {
    padding: 0 !important;
    overflow: auto;
  }

  .canvasLoader {
    position: relative;
    z-index: 2000;
    overflow: hidden;
  }
}


.bgBlur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}