@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.modalFooter {
  background: $whiteTwo;
  color: $slateGrey;
  text-align: center;
  padding: 20px 0;
  font-size: 13px;
  @include mq("sm", max) {
    margin-top: auto;
  }
}
.closeBtn.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  padding: 10px;
}

.centerAlignForm {
  @extend %mtAuto;
}
