.colorWheel {
  position: absolute;
  left: 40px;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: 1200;
}

.overlay-conversation-modal .colorpick-drag {
  top: auto;
  bottom: 40px;
  right: -40px;
  left: auto;
}

.IroWheel , .IroColorPicker {
  border-radius: 50%;
}

.IroWheel:hover {
  cursor: url('../../assets/images/picker02.svg') 8 74, auto;
}
  

.IroWheel::before {
  content: "";
  background: url('../../assets/images/outlined-circle.svg') no-repeat center center;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 2;
  left: 0;
  right: auto;
  border-radius: 50% 50% 50% 40%;
} 
/* .IroHandle circle:nth-child(2) {
  opacity: 0;
} */

.IroSlider:hover {
  cursor: url(/static/media/picker02.9a8e064e.svg) 8 74, auto;
}

.IroSliderGradient {
  border-radius: 5px !important;
  /* border: 2px solid #000 !important; */
}

.IroSliderGradient::before {
  content: '';
  background-image: url('../../assets/images/bar1.svg') !important;
  background-size: contain;
  width: 100%;
  height: 25px;
  display: block;
  position: absolute;
  top: -2px;
  left: 1px;
  background-repeat: no-repeat;
  z-index: 1500;
}

/* .dot-border {
  position: absolute;
  left: 210px;
  top: 360px;
}

.drawingReply .colorWheel {
  left: 300px !important;
  z-index: 9999;
  top: 90px !important;
}

.leftie {
  position: absolute;
  left: 400px;
} */

.middle3 {
  z-index: 9999;
}

.colorpick-drag {
  position: absolute;
  display: flex;
  width: 220px;
  height: 280px;
   background: url('../../assets/images/CW-BG1.svg') no-repeat center center;
  background-size: cover;
  cursor: grab;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  z-index: 9999;
  align-items: center;
  border-radius: 10px;
  backdrop-filter: saturate(180%) blur(10px);
}

.colorpick-drag .colorWheel {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.colorpick-wrap .middle3 {
  height: 100%;
  flex-basis: 100%;
  width: 100%;
}