.overlay {
    position: absolute;
    z-index: -1;
    left: 18px;
    top: 22px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.overlay .dot-border {
    cursor: default;
}
.dot-border {
    background: url("../DrawingCanvas/images/arrow-top.svg");
    background-size: contain;
    width: 35px;
    height: 35px;
    border: none;
    cursor: default;
}
.dot-color {
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(56% 11%, 8% 90%, 92% 90%);
    width: 35px;
    height: 35px;
}

.dot {
    width: 35px;
    height: 35px;
    z-index: 5;
}

.colorsWrap > div , .colorPickerPallete {
  width: 35px;
}
