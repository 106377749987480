@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";

@import "./application.css";
@import "./spectrum_1.8.0.css";

.drawingCanvas {
  >div[aria-hidden] {
    background-color: rgba(255, 255, 255, 0.2);
  }

  // reset
  .overlay-modal-in,
  .scrollarea {
    width: initial;
    height: initial;
  }

  .sketchpad-area {
    .sketchpad-box {
      height: auto !important;
    }
  }

  .scrollarea {
    overflow: initial;
    -webkit-overflow-scrolling: initial;
  }

  // Custom
  .closeLightbox {
    @include mq("md", "max") {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  /* MH Stylings start here */
  .toolbox-cover {
    position: relative;
    width: 110px;
    margin: 0 auto;
    height: 420px;

    &.kit-logo-v {
      &::before {
        content: "";
        background: url('../../assets/images/logo-colored.svg') no-repeat center center;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 50px;
        top: -10px;
        z-index: 2;
        left: -6px;
        right: auto;
        display: none;
      }
    }

    .toolbox {
      //background: url('images/logo-white.svg') no-repeat center center;
      background: url('images/toolkit3.svg') no-repeat center center;
      position: relative;
      background-size: contain;

      .btn-reset {
        padding: 0;

        .toTrash {
          background: url("images/Delete outlined.svg") no-repeat center center;
          background-size: contain;
          width: 30px;
          height: 35px;

          &:hover {
            background: url("images/Delete filled.svg") no-repeat center center;
            background-size: contain;
          }
        }

        &:active,
        &:focus {
          outline: 0;
        }
      }

      &.custom-color {
        .sp-replacer {
          position: absolute;
          border: none;
          background: url("images/arrow-top.svg");
          width: 65%;
          height: 65%;
          background-repeat: no-repeat;
          background-size: contain;
          padding: 3px;

          &:nth-child(2) {
            top: 23px;
            left: 0px;
            z-index: 1;
            padding: 0;
            width: 47px;
            height: 46px;
          }

          &:nth-child(4) {
            background: url("images/arrow-bottom.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 46px;
            top: 43px;
            height: 52px;

            .sp-preview-inner {
              transform: rotate(180deg);
              clip-path: polygon(62% 1%, 2% 92%, 120% 92%);
            }
          }


        }

        /* Active icons styling */


        .down {
          .pencil {
            background: url('./images/pencil-colored1.svg') no-repeat center center;
            background-size: contain;
          }

          .round_brush {
            background: url('images/round Brush Colored.svg') no-repeat center center;
            background-size: contain;
          }

          .flat_brush {
            background: url('images/Flat brush colored.svg') no-repeat center center;
            background-size: contain;
          }

          .marker {
            background: url('images/marker-colored1.svg') no-repeat center center;
            background-size: contain;
          }

          .eraser {
            background: url("images/Eraser colored.svg") no-repeat center center;
            background-size: contain;
          }

        }



        /* Pencil tool styling */
        .pencil {
          background: url('./images/pencil-white1.svg') no-repeat center center;
          background-size: contain;
          left: 18px;
          top: 20px;
          width: 85px;
          height: 30px;
          transform: rotate(-61deg);


          &:hover {
            background: url("images/pencil-colored1.svg") no-repeat center center;
            background-size: contain;
          }
        }

      }

      /* Pencil tool styling end */

      /* Brush tool styling */

      .round_brush {
        width: 85px;
        height: 20px;
        background: url('./images/round Brush.svg') no-repeat center center;
        background-size: contain;
        transform: rotate(-31deg);
        left: 3px;

        &:hover {
          background: url("images/round Brush Colored.svg") no-repeat center center;
          background-size: contain;
        }

      }

      /* Brush tool styling end */

      /* Flat  tool styling */

      .flat_brush {
        width: 105px;
        height: 16px;
        background: url('./images/Flat brush.svg') no-repeat center center;
        background-size: contain;
        transform: rotate(-36deg);
        left: 10px;
        top: 0px;

        &:hover {
          background: url("images/Flat brush colored.svg") no-repeat center center;
          background-size: contain;
        }

      }

      /* Flat tool styling end */

      /* Marker tool styling */

      .marker {
        background: url('./images/marker-white1.svg') no-repeat center center;
        background-size: contain;
        width: 60px;
        height: 26px;
        transform: rotate(-17deg);
        left: 4px;


        &:hover {
          background: url("images/marker-colored1.svg") no-repeat center center;
          background-size: contain;
        }

      }

      /* Marker tool styling end */

      /* Eraser tool styling */

      .eraser {
        width: 75px;
        height: 35px;
        background: url('./images/Eraser.svg') no-repeat center center;
        background-size: contain;
        left: -52px;
        top: 27px;

        &:hover {
          background: url("images/Eraser colored.svg") no-repeat center center;
          background-size: contain;
        }

      }

      /* Eraser tool styling end */

      /* forward tool styling */

      .go_forward {
        &_li {
          margin-top: -24px;
          left: -27px;

          .goForward {
            background: url("images/Arrow left.svg") no-repeat center center;
            background-size: contain;
            width: 35px !important;
            height: 35px;
            top: 24px;
            left: 7px;

            &:hover {
              background: url("images/Arrow left filled.svg") no-repeat center center;
              background-size: contain;
            }
          }
        }
      }

      .go_back {

        &_li {
          margin-top: -2px;
          right: -12px;

          .goBack {
            background: url("images/Arrow right.svg") no-repeat center center;
            background-size: contain;
            width: 35px !important;
            height: 35px;
            top: 20px;
            left: -1px;

            &:hover {
              background: url("images/Arrow right filled.svg") no-repeat center center;
              background-size: contain;
            }
          }
        }
      }

      .sp-preview {
        background-image: none;

        .sp-preview-inner {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          clip-path: polygon(55% 9%, 10% 90%, 89% 91%);
        }

      }

      .color-picker {
        position: relative;
        margin: 0 auto;
        width: 48%;
        top: 3px;
        left: -9px;
      }

      .clear-button {
        display: inline-block;
        width: 30px;
        position: absolute;
        left: 22px;
        right: 5px;
        bottom: 22px;
      }

      .tool-icons {
        margin-top: 12px;
        margin-bottom: 0px;
        padding-top: 20px;
        top: 45px;
        position: relative;

        ul {
          width: 45px;
          display: inline-block;

          .index-list {
            display: flex;
            flex-direction: row;
            align-items: center;

            li {
              padding: 3px 3px;
            }
          }

          li {
            position: relative;

            .icon {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }

    .toolbox-area {
      background: white;
      flex-basis: 153px;
      height: 460px;
      flex-grow: 0;
      flex-shrink: 0;
      width: 153px;
      z-index: 1210;
      cursor: -webkit-grab;
      cursor: grab;

    }

    .drawingCanvasPaper {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .toolbox-cont {
      canvas {
        border: 1px solid #d2d2d2;
      }
    }

    .brush_size_slider {
      .brush_size_slider_in {
        padding: 6px 4px;
        background: url('images/bg-brush-slider.svg') no-repeat center center;
        background-size: contain;
        height: 215px;
        width: 90px;
        -webkit-border-radius: 0 5px 5px 0;
        border-radius: 0 5px 5px 0;
      }
    }
  }

  #dummyCanvasContainer #background_dummy_canvas_container {
    // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==');
    background-image: none;
  }


  /******************** LEVEL GAMIFICATION **********************/
  p.expParagraph {
    display: inline-block;
    vertical-align: top;
  }

  ul.levelsUl {
    display: inline-block;
    list-style-type: none;
    vertical-align: top;
    margin: 3.4px 0 0 10px;
    padding: 0;
  }

  ul.levelsUl li {
    width: 11px;
    height: 11px;
    float: left;
    margin-right: 3px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
  }

  .level-1 {
    background-color: $dotLevel1;
  }

  .level-2 {
    background-color: $dotLevel2;
  }

  .level-3 {
    background-color: $dotLevel3;
  }

  .level-4 {
    background-color: $dotLevel4;
  }

  .level-5 {
    background-color: $dotLevel4;
  }

  .level-6 {
    background-color: $dotLevel4;
  }

  .level-7 {
    background-color: $dotLevel4;
  }

  .level-8 {
    background-color: $dotLevel4;
  }

  .level-9 {
    background-color: $dotLevel4;
  }

  .level-10 {
    background-color: $dotLevel4;
  }

  /******************** END LEVEL GAMIFICATION **********************/
  .toolbox_click {
    ul {
      height: 85px;
      width: 140px !important;
      border-radius: 0 5px 5px 0;
      position: absolute;
      top: -120px;
      left: 45px;
      padding: 0 5px !important;
      z-index: 9999;
      background-size: 100%;

      li {
        padding: 3px 3px;
        position: relative;
        top: -7px;
      }
    }
  }

  .marker_slider_in {
    ul {
      background: url('images/bd-1.svg') no-repeat center center;
      background-size: 100%;
      top: -119px;
      left: 32px;
    }
  }

  .round_slider_in {
    ul {
      background: url('images/bd-2.svg') no-repeat center center;
      background-size: 100%;
      top: -120px;
      left: 30px;
    }
  }

  .flat_slider_in {
    ul {
      background: url('images/bd-3.svg') no-repeat center center;
      background-size: 100%;
      top: -130px;
      left: 75px;
    }
  }

  .eraser_slider_in {
    ul {
      background: url('images/bd-4.svg') no-repeat center center;
      background-size: 100%;
      left: auto;
      right: -10px;
      top: auto;
      bottom: 16px;
    }
  }

  .overlay-modal-in {
    .railarea {
      width: 100%;
      min-width: 1290px;
      min-height: 100vh;
      overflow: hidden;

      .drawingCanvasControlPanel {
        -ms-flex: 0 0 250px;
        -moz-flex: 0 0 250px;
        flex: 0 0 250px;
        position: relative;
        background: url(/static/media/box-bg.e894c5c5.svg) no-repeat center center;
        background-size: cover;
      }

      .content-base {
        flex-shrink: 0;
        flex-grow: 1;
        flex-basis: 800px;
        max-width: 800px;
      }
    }

  }
}