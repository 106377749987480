@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.branchingWrapper {
  overflow-x: auto;
  svg {
    height: 260px;
  }
}
.branchingScrollHorizontalTrack {
  background: rgba(0, 0, 0, 0) !important;
  height: 6px;
  > div {
    background: rgba(0, 0, 0, 0.05) !important;
  }
}

.spinner {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  > div {
    border: 10px solid $whiteThree;
    border-radius: 50%;
    border-top: 13px solid $blue;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
  > span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 17vh;
    color: $blue;
    white-space: nowrap;
    font-size: 14px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
