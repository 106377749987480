@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";

.menuItems {
  button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
}

.profileBtn {
  &.profileBtn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    min-width: 1px;
    min-height: 1px;
    display: block;
    overflow: hidden;
    margin: 0 auto 5px;
    border: 2px solid #7e8282;
    background-color: #fff;

    &:hover {
      background: inherit;
    }
  }
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 135px;
  text-align: left;
  width: 100px;
  padding-bottom: 20px;

  .btnedit {
    background: white;
    border: 0;
    height: auto;
    padding: 8px 0;
    width: 100%;

    &>span {
      display: inline-block;
    }

    img {
      max-width: 34px;
      display: block;
      margin: 0 auto;
    }
  }

  &.hidePanel {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .wrapper {
      display: none;
    }
  }

  .hamburgerMenu {
    background: inline-svg('<?xml version="1.0" encoding="UTF-8"?> <svg version="1.1" viewBox="0 0 33 27" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>menu-button</title> <desc>Created with Sketch.</desc> <g fill="none" fill-rule="evenodd"> <g transform="translate(-7 -689)"> <g transform="translate(0 62)"> <image x="7.3811" y="627.64" width="32" height="26" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAYAAADWm14/AAABhGlDQ1BJQ0MgUHJvZmlsZQAAKJGVkTtIA0EQhv+LSArjowgiqQ6UVFFiBFEQIUkhQooYUqhoca88IF6OyxkVLQUtAxaijWIaC2ttA7ZaKYogFlb2QRsN5+xt9CKi4g/HfDc7u7PzL+A5lgyj4OkBlnTLTE3FxNm5edH7iA4E4EhSSkY0mUww/ohf9XIDgcWrQXbW9/Vf5VO1kgIIfmJLMUyLeJu4f8UyGJ8Q+026FHGNcZbzNWOZ85NTk07Fid+Iu8pKlvZ6fMRhXc3rxBPEk0pOUok3iENyS322hfl9HAXiUiEvm5KlqSKzJl4sFM2SISnaP4f8S5a2arEYLxprZj6bs8QoOamJ07oyFBIj4eExgL0Lr67POH4LvRdurngIjD0DbRU3J+8CZ1tA352bGzgAujeB03Nl2Sw32wug7tyz5r/wSfiJua+ORoHqLZBeBxKXwN4+EMxQnwUg2Un5cZpw0f24SpmRCD/LFwPaH2y7HgS8O0CjYtuvR7bdqNI890BNfwdEbm+h0SGQAQAAAHhJREFUSA3tlckNwCAMBE2g/z6SNpIqoBhgHtQwSMGShfzawyuTIqLM/m+lKf025UOgmwQuExxsAthMEqxAd8E0IHCA1ooMqAQ05QsY9e8ajBcC5xB9hvXbYJIB9RLmbaywiOgrgEC11IN7DhG/4WOuQMcmA7ig1QBpYAfCRx+VPAAAAABJRU5ErkJggg=="/> </g> </g> </g> </svg>');
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;

    &.hideMenu {
      display: none;
    }
  }

  .closeNav {
    width: 32px;
    height: 32px;
    background: inline-svg('<?xml version="1.0" encoding="UTF-8"?> <svg version="1.1" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>cross</title> <desc>Created with Sketch.</desc> <g fill="none" fill-rule="evenodd" opacity=".2"> <g transform="translate(-11 -684)"> <image x="11.958" y="684.9" width="24" height="24" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAYRpQ0NQSUNDIFByb2ZpbGUAACiRlZE7SANBEIb/i0gK46MIIqkOlFRRYgRRECFJIUKKGFKoaHGvPCBejssZFS0FLQMWoo1iGgtrbQO2WimKIBZW9kEbDefsbfQiouIPx3w3O7uz8y/gOZYMo+DpAZZ0y0xNxcTZuXnR+4gOBOBIUkpGNJlMMP6IX/VyA4HFq0F21vf1X+VTtZICCH5iSzFMi3ibuH/FMhifEPtNuhRxjXGW8zVjmfOTU5NOxYnfiLvKSpb2enzEYV3N68QTxJNKTlKJN4hDckt9toX5fRwF4lIhL5uSpakisyZeLBTNkiEp2j+H/EuWtmqxGC8aa2Y+m7PEKDmpidO6MhQSI+HhMYC9C6+uzzh+C70Xbq54CIw9A20VNyfvAmdbQN+dmxs4ALo3gdNzZdksN9sLoO7cs+a/8En4ibmvjkaB6i2QXgcSl8DePhDMUJ8FINlJ+XGacNH9uEqZkQg/yxcD2h9sux4EvDtAo2Lbr0e23ajSPPdATX8HRG5vodEhkAEAAAGgSURBVFgJzZcLboQwDERRpR6xVb8q/Vxgz7NSb1q1no3fynJhKRBCLRHj2J6ZAMuGrit2NPfh59fut3Bgi0ucJ/u08duPuzLVUehhFQemOOATd3cIE0rca9KMhhKtG8ESNuTy4j6ZLklM1BQxRs4tP680i3hwcQB4OMvRK6y4QMjJn0W8p8I1IgDP5OKQkS9RmKghAvA/k6OCxjebiJfs0QvIUz/kqVFPxBCmjHyJBkYKloigdzE5egDKIp68gDz18sypJq781YvIezjtaBBABBwSQW01cuQBfEkENZm8dxDyYM72AAgwXonngKTzmFOtjN4SrRgB6g0jEt1YrCPOvVgso6dEFUYAe8OKhPF8M3L0I+LWRXyZ1yERmpNRU6KJ8Woi/6/SrKw3VfGyx/PNbsEYeZOHcIy8yc8Q8vwiglx5ajQXb4dulYx8iWaMNGbyJq9iyHf5M1pCzoWlN/8vaCEy8iUaGCnIK2+yIYE8b8nmkLMmsPLGpMl+MIuY3BeidtdteSZv+mGy+6fZ7h+nemiOdvz6XOJpqujj8ybO7gdnw9a3UfkQiAAAAABJRU5ErkJggg=="/> </g> </g> </svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .menuItems {
    width: 100%;
  }
}

li.CustomNavItem {
  padding-left: 25px;
  border-bottom: solid 1px $greyWhite;

  &:last-child {
    border-bottom: none;
  }
}

.hamburger {
  width: 63px;
  height: 58px;
  background-color: $beige;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburgerItem {
  display: block;
  width: 24px;
  position: relative;
  height: 2px;
  background: black;

  &:before {
    display: block;
    content: "";
    width: 24px;
    position: absolute;
    height: 2px;
    transform: translate(0, -6px);
    background: inherit;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    transform: translate(0, 6px);
    width: 24px;
    height: 2px;
    background: inherit;
  }
}

.headerMain {
  background: white;
  height: 60px;
  border: solid 1px $gray;
  padding: 0 0 0 25px;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  align-items: center;
  z-index: 10;
}

.resNavItme {
  display: flex;
  padding: 0;
  margin: 0;

  li {
    border-left: solid 1px $gray;
    display: flex;

    button {
      min-width: 54px;
    }

    button.signLogin {
      padding: 0;
      margin: 0;
      width: 81px;
      border-radius: 0;
      font-size: 11px;
      letter-spacing: 0.4642857px;
      line-height: normal;
      height: auto;
      min-height: 29px;
      text-transform: inherit;

      &:first-child {
        border-bottom: solid 1px $gray;
      }
    }
  }

  li.notSignedIn {
    display: flex;
    flex-wrap: wrap;
    width: 82px;
    flex-direction: column;
  }
}

.closeModal {
  &.closeModal {
    padding: 0;
    min-width: 1px;
    height: 100%;
    min-height: 1px;
  }
}

.btnYellow {
  &.btnYellow {
    width: 47px;
    height: 35px;
    min-width: 1px;
    min-height: 1px;
    background-color: transparent;
    color: black;
    border-radius: 0;
    margin-left: 50px;
    margin-top: 10px;
    text-transform: inherit;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    .MuiTouchRipple-root-310 {
      display: none;
    }
  }

  >span {
    height: 100%;
    line-height: 1;
  }
}

.logoutImg {
  height: 40px
}

#mouse_over {
  opacity: 0;
}

#mouse_over:hover {
  opacity: 1;
}

.profile-icon-wrap {
  position: relative;

  .verticalLine {
    position: absolute;
    left: 0;
    top: auto;
    bottom: 10px;
    z-index: 9;
  }

  .profileLevel {
    margin-bottom: 40px;

    img {
      margin: auto;
    }
  }

  .badgeDot {
    margin: 3px auto;
  }


}

.exploreWrap {
  width: 100%;
  margin: 22px 0;
}

.preLoginWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  >button {
    &:first-child {
      &:hover {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }

  button {
    margin-bottom: 15px;
  }
}

.notification {
  position: relative;
  top: auto;
  bottom: 8px;
  left: 0px;

  button {
    bottom: 10px;
    left: 3px;
  }
}

.startThreadWrap {
  margin: 20px 0 30px 0;
}

.editorialWrap {
  display: flex;
  justify-content: center;
}