@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.resetPassword {
  @extend %mtAuto;
  @extend %mbAuto;
  .aligntCenterBtn {
    justify-content: center;
  }
  .spacing0 {
    margin: 6px 0 23px;
  }
}
