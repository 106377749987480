@import "../../../assets/styles/_mixins";

.blogHeader{
    margin-top: 30px;
    border-bottom: 1.5px solid;
    @include mq("sm", min) {
        display: flex;
        align-items: center;
    }
}

.editorialHeading{
    font-size: 70px;
    font-weight: 600;
    margin-left: 5px;

    @include mq("md", min) {
        font-size: 80px;
    }
}

.blogLogo{
    cursor: pointer;

    @include mq("sm", max) {
        width: 110px;
        height: auto;
    }
}