.noDrawingMsg {
  > p {
    font-size: 18px;
    text-align: center;
    > a {
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      outline: 0;
    }
  }
}
