@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.closeLightbox {
  &.closeLightbox {
    position: fixed;
    // had to place fix due to modal
    right: 33px;
    top: 26px;
    background: inline-svg('<?xml version="1.0" encoding="UTF-8"?> <svg version="1.1" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"> <title>message close</title> <desc>Created with Sketch.</desc> <g fill="none" fill-rule="evenodd" stroke-linecap="square"> <g transform="translate(-278 -19)" stroke="#000" stroke-width="2"> <g transform="translate(-14 -5)"> <g transform="translate(292.82 25.106)"> <path d="m0.66667 0.47715 18.856 18.856"/> <path d="m0.66667 19.333 18.856-18.856"/> </g> </g> </g> </g> </svg>');
    padding: 0;
    border-radius: 100%;
    min-width: 1px;
    z-index: 100;
    width: 50px;
    height: 50px;
    background-size: 21px 21px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid transparent;

    &:hover {
      border-color: black;
      background-color: transparent;
    }

    @include mq("sm", max) {
      right: 15px;
      top: 15px;
      width: 24px;
      position: absolute;
    }
  }
}

.blogWrap {
  display: flex;
  padding: 25px;
  @include mq("md", min) {
    padding: 60px;
  }
}

.title {
  padding-left: 20px;
}

.date {
  font-size: 16px;
  line-height: 1;
  padding: 4px 20px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.publishBy {
  font-size: 16px;
  line-height: 1;
  padding: 4px 15px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.blog-infor {
  div {
    img {
      margin-bottom: 50px;
      margin-top: 50px;
      height: 100%;
      // max-height: 450px;
      width: 100%;
      object-fit: contain;
    }
    padding-bottom: 1px;
    list-style: none;
  }

  p {
    margin-bottom: 28px;
    font-size: 24px;
    font-family: mr-eaves-sans, serif;
    line-height: 30px;
    font-weight: 300;
  }

  .mainContainer {
    display: block;

    @include mq("sm", min) {
      display: flex;
    }

    p {
      @include mq("sm", max) {
        margin-bottom: 12px;
        border: 2px solid #000;
      }
    }
  }
}

.share-text {
  font-size: 22px;
  font-family: $defaultFamily;
}

.close-button {
  font-size: 18px;
  padding-right: 20px;
  color: #000 !important;
  font-family: $defaultFamily;
  cursor: pointer;
}

.close-button:hover {
  text-decoration: none !important;
  color: inherit !important;
}

.share {
  padding-left: 20px;
  padding-top: 20px;
}

figcaption {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: -30px !important;
}

a {
  color: #428bca !important;
  text-decoration: none;
  font-size: 24px;
  &:hover {
    color: black !important;
    text-decoration: underline !important;
  }
}

li {
  font-size: 19px;
}

ul {
  margin-top: -30px;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.subtitle h2 {
  font-size: 14px;
}

.avatar {
  font-size: 48px;
  line-height: 95px;
  font-weight: 900;
  margin-bottom: 5px;
  height: 55px !important;
  width: 55px !important;
}

.blogTitleDiv {
  flex-direction: column;
}

.dot {
  align-items: flex-end;
}

.blogTitle {
  flex: 3
}

.blogWrap .title {
  color: #000;
  line-height: 1;
  margin: 10px 0;
  padding: 0;
  font-weight: 600;
  font-size: 52px;
  font-family: $defaultFamily;
}

.dotWrapper {
  flex-direction: row;
  padding-right: 15px;
  margin-top: 8px;
}

.blog {
  max-width: 800px;
  margin: auto;
}

.levelsCom {
  padding-left: 36px;
}

.avatarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: "130%";
}

.shareForLightbox {
  width: 16px;
  height: 19px;
  opacity: 0.6;
  cursor: pointer;
  background-image: inline-svg('<?xml version="1.0" encoding="UTF-8"?> <svg viewBox="0 0 20 23" xmlns="http://www.w3.org/2000/svg"> <g fill="none" fill-rule="evenodd" stroke="#{black}" stroke-linecap="square" stroke-width="2"> <path d="m1.463 8.628h3.927m0.654-4.302 3.271-3.074 3.272 3.074m0.655 4.302h3.926m-15.668-0.018v13.028m7.815-19.609-0.315 11.748m-5.858 7.794 14.08-0.65m-0.054-1e-3v-10.914"/> </g> </svg>');
  background-size: contain;
  background-repeat: no-repeat;
}

.avatarName {
  padding: 19px 0px 0px 5px;
  font-size: 16px;
  line-height: 1;
}

.indvdlPage {
  padding-bottom: 10px;

  img {
    width: 150px;
    height: 70px;
  }

  .editorialHeading {
    width: 150px;
    height: 70px;
    font-size: 60px;
  }
}