.dadaModal {
  .dadaBackDrop {
    background-color: rgba(white, 0.9);
  }
  .dadaDialogPaper {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(black, 0.5);
    border: 1px solid black;
  }
  .dadaModalTitle {
    font-size: 24px;
  }
  .dadaModalFooter {
    .btn {
      width: auto;
      text-transform: initial;
      font-size: 16px;
      &.btnTransparent {
        color: $tundora2;
      }
      &.btnGreen {
        min-width: 80px;
        font-size: 24px;
        line-height: 1;
        padding: 12px 21px;
      }
    }
    &.hasBlockBtns {
      flex-direction: column;
      .btn {
        + .btn {
          margin: 20px 0;
        }
      }
    }
  }
  &.dadaEmptyModal {
    .dadaModalTitle {
      .dadaTitle {
        margin-top: 0;
      }
    }
    .dadaDialogPaper {
      max-width: 512px;
    }
  }
  &.dadaActionModal {
    .dadaDialogPaper {
      max-width: 520px;
      .dadaTitle {
        margin-top: 34px;
      }
      .btn {
        margin:30px 0;
      }
    }
  }
  &.draftModal {
    .dadaDialogPaper {
      width: auto;
    }
  }

  .vhAlignCenter {
    > .dadaDialogPaper {
      background-color: #fff;
    }
  } 
}

// Draft Images
.draftsImages {
  @extend .list-unstyled;
  display: flex;
  align-items: center;
  li {
    padding: 20px;
    img {
      cursor: pointer;
      border: 1px solid $gray;
    }
  }
}

.closeModalIcon{
  .btnReset {
    min-width: 30px;
    position: absolute;
    right: 20px;
    top: 10px;
    .icon {
      background: url("../../assets/images/postDrawingClose.svg");
      width: 15px;
      height: 15px;
      background-size: cover;
      display: block;
    }
  }
}
