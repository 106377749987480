@import "variables"; // Needs to fix
$breakpoints: (
  "sm": 768px,
  "md": 1025px,
  "lg": 1366px,
  "xl": 1500px
);

@mixin mq($breakpoint, $type: min) {
  @if map_has_key($breakpoints, $breakpoint) {
    $width: map_get($breakpoints, $breakpoint);
    @if $type==max {
      $width: $width - 1px;
    }
    @media (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-only($min-width, $max-width) {
  @if map_has_key($breakpoints, $min-width) and
    map_has_key($breakpoints, $max-width)
  {
    $min-width: map_get($breakpoints, $min-width);
    $max-width: map_get($breakpoints, $max-width) - 1px;
    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }
}

@mixin centered-element($top, $left, $x, $y) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate($x, $y);
}

@function rem($pixels) {
  @return ($pixels / 16) * 1rem;
}

// Replace letters
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// Encode symbols
@function url-encode($string) {
  $map: (
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    " ": "%20",
    "!": "%21",
    "*": "%2A",
    "'": "%27",
    '"': "%22",
    "(": "%28",
    ")": "%29",
    ";": "%3B",
    ":": "%3A",
    "@": "%40",
    "&": "%26",
    "=": "%3D",
    "+": "%2B",
    "$": "%24",
    ",": "%2C",
    "/": "%2F",
    "?": "%3F",
    "#": "%23",
    "[": "%5B",
    "]": "%5D"
  );

  $new: $string;

  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }

  @return $new;
}

// Format the SVG as a URL
@function inline-svg($string) {
  @return url("data:image/svg+xml,#{url-encode($string)}");
}

%textTruncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

%mtAuto {
  @include mq("sm", max) {
    margin-top: auto;
  }
}

%mbAuto {
  @include mq("sm", max) {
    margin-bottom: auto;
  }
}

%lineHelper {
  width: 12px;
  height: 1px;
  background: $pinkishGrey;
  position: absolute;
}