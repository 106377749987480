@import "../../assets/styles/_mixins";

.editorialContainer{
    height: auto;
    width: auto;
    margin: 30px;
}

.detailsbox.read {
	span {
		&:not(.open-link-icon) {
			border-bottom: 1px solid #000;
		}
	}
}

.description {
	&.pr-small {
		padding-right: 80px;
		@include mq("lg", max) {
			padding-right: 0;
		}
	}

	&.pr-medium {
		padding-right: 100px;
		@include mq("lg", max) {
			padding-right: 0;
		}
	}

	&.pr-large {
		padding-right: 120px;
		@include mq("lg", max) {
			padding-right: 0;
		}
	}
}
