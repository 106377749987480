@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
.responsiveLogo {
  > img {
    max-width: 80px;
    width: 100%;
    @include mq("sm", max) {
      max-width: 45px;
    }
  }
}