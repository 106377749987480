@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.exploreViewWrapper {
  display: flex;
  height: 100vh;
  .exploreAside,
  .exploreContent {
    width: 100%;
  }
  .exploreContent {
    flex-grow: 1;
    overflow: auto;
  }

  .customChips {
    &.customChips {
      position: relative;
      // margin: 30px;
      .customChipsInputField {
        width: 100%;
        display: flex;
        div[role="button"] {
          background: $beige;
          border-radius: 0;
          margin: 4px 4px 4px 6px;
          height: auto;
          position: relative;
          span {
            padding: 5px 5px 5px 18px;
            letter-spacing: 0.46px;
            display: block;
            max-width: 164px;
            @extend %textTruncate;
            &:empty {
              display: none;
            }
          }
          svg {
            font-size: 0;
            width: 8px;
            height: 8px;
            position: absolute;
            left: 15px;
            background-image: inline-svg(
              '<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g transform="translate(-278.000000, -19.000000)" stroke="black" stroke-width="2"><g transform="translate(-14.000000, -5.000000)"><g transform="translate(292.818187, 25.106457)"><path d="M0.666666667,0.477152502 L19.5228475,19.3333333"></path><path d="M0.666666667,19.3333333 L19.5228475,0.477152502"></path></g></g></g></g></svg>'
            );
            background-size: cover;
            background-repeat: no-repeat;
            path {
              opacity: 0;
            }
          }
        }
        input {
          width: 140px;
          max-width: 100%;
          // padding-left: 45px;
          color: $tundora2;
          font-size: 16px;
        }
        > div {
          // border: solid 1px $pinkishGrey;
          background-color: white;
          display: flex;
          flex-wrap: wrap;
          max-height: 100px;
          overflow-x: hidden;
          overflow-y: auto;
          border: none;
          box-shadow: 0 0 10px #0000000a;

          &:before {
            background: transparent;
            border: none !important;
          }
          &:after {
            display: none;
          }
          ::placeholder {
            color: $slateGrey;
          }
        }
      }
      .addChipBtn {
        position: absolute;
        bottom: 14px;
        right: 15px;
        width: 20px;
        height: 20px;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .inviteFriends {
      p {
        font-size: 1rem;
      }
    }
  }

  .inviteFriends {
    width: 100%;
    max-width: 270px;
  }
}

// Aside Colomn
.exploreAside {
  display: flex;
  flex-flow: column;
  flex: 1 0 350px;
  max-width: 350px;
  background-color: $whiteTwo;
  .exploreAsideHeader {
    border-bottom: 1px solid $french-gray;
    padding-top: 20px;
    flex-shrink: 0;
    position: relative;
  }
  .exploreAsideBody {
    flex-grow: 1;
    padding: 26px;
    overflow: auto;
  }
}

// explore aside header
.exploreAsideHeader {
  .responseMsg {
    background-color: $beige;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    margin: 0;
    text-align: center;
  }
}
.exploreInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .exploreAvatarWrap,
  .exploreData,
  .drawingCountWrap {
    padding: 5px 15px;
  }
}
// Profile Avatar
.exploreAvatarWrap {
  flex-shrink: 0;
  text-align: center;
  .exploreAvatar {
    height: 65px;
    width: 65px;
    display: inline-block;
    border: 4px solid white;
    box-shadow: 0 2px 4px 0 rgba($darkGray1, 0.5);
  }
  .uploadLabel {
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 100;
    color: $french-gray;
    font-size: 10px;
  }
  .inputFile {
    display: none;
  }
  // edit button on explore page
  .btn {
    font-weight: 300;
    padding: 2px 10px;
    border-radius: 0;
    margin-top: 12px;
  }
}
.exploreData {
  text-align: center;
  min-width: 0;
  .exploreTitles {
    h4,
    h6 {
      margin: 0 0 5px;
      position: relative;
    }
    h4 {
      display: inline-flex;
      .followIcon {
        opacity: 1;
        position: relative;
        width: 18px;
        display: inline-block;
        vertical-align: top;
        min-width: 0;
        margin-left: 5px;
      }
    }
    h6 {
      @extend %textTruncate;
    }
    input {
      margin: 0 0 5px;
      width: 200px;
      text-align: center;
      font-size: 14px;
      &.exploreName {
        font-size: 18px;
      }
    }
  }
}
// drawing Count
.drawingCountWrap {
  .drawingCount {
    background-image: inline-svg(
      '<svg width="49px" height="65px" viewBox="0 0 49 65" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><filter x="-18.6%" y="-9.8%" width="134.9%" height="127.9%" filterUnits="objectBoundingBox" id="filter-1"><feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix><feMerge><feMergeNode in="shadowMatrixOuter1"></feMergeNode><feMergeNode in="SourceGraphic"></feMergeNode></feMerge></filter></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g filter="url(#filter-1)"><g stroke-linecap="square" stroke-width="2"><polygon stroke="white" fill="white" points="0.727466256 0.926170033 0.727466256 60.1222089 18.8997933 42.8252274 36.7396737 60.3469802 37.6682019 0.0859548391"></polygon><path d="M0.727466256,11.1845276 L36.7391649,10.8414366" stroke="#{$whiteTwo}"></path></g><g transform="translate(20.982593, 24.277155)"><path d="M17.2433354,0.533840414 C17.2433354,0.533840414 15.2238983,5.41257344 13.6354702,9.26145695 C12.0470421,13.1103405 9.66495961,19.3404497 9.66495961,19.3404497 L13.6354702,21.0879447 L17.6541366,11.2573053 L21.7677747,2.05954333 L17.2433354,0.533840414 Z" fill="#{$whiteTwo}"></path><path d="M10.0322705,19.6099947 C10.0322705,19.6099947 7.03756201,20.1704235 5.54224906,22.9600164 C4.0469361,25.7496093 4.98072404,28.8955621 3.99768792,30.867858 C3.01465179,32.840154 0.257574909,33.5628226 0.257574909,33.5628226 C0.257574909,33.5628226 2.15132275,35.6362274 6.01945952,33.5576147 C9.88759629,31.4790019 11.458085,29.0378837 12.789763,26.8284264 C14.121441,24.618969 13.4613113,21.210445 13.4613113,21.210445 L10.0322705,19.6099947 Z" fill="#{$orange}"></path></g></g></g></svg>'
    );
    background-size: cover;
    background-repeat: no-repeat;
    width: 49px;
    height: 65px;
    text-align: center;
    > span {
      position: relative;
      font-size: 16px;
      display: inline-block;
      top: 20%;
      left: -5px;
    }
  }
}

// Profile Action
.exploreAction {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .ActionLabel {
    margin: 0;
  }
  .ActionField {
    width: 200px;
    height: 26px;
    margin-right: 15px;
  }
  .btn {
    padding: 2px 8px;
    border-radius: 0;
    background-color: $gray;
    margin: 0 5px;
    &:hover {
      background-color: darken($gray, 5%);
    }
  }
}

// Progess Count
.progressBarCount {
  display: flex;
  place-content: center space-between;
  flex: 1 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 12px;
}

.exploreContent {
  .exploreContentHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 20px 0 30px;
    .responsiveLogo {
      > img {
        max-width: 50px;
      }
    }
  }
  .exploreContentBody {
    padding: 50px 30px 30px 30px;
  }
}

.exploreLogo {
  margin-bottom: 20px;
  > img {
    min-width: 100%;
    min-height: 100%;
    @include mq("sm", max) {
      max-width: 60px;
    }
  }
}

.explore-dots {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-left: 0;
  justify-content: flex-end;
  padding-right: 70px;
  .dot {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-left: 15px;
    // margin-top: 20px;
    // margin-left: 20px;
  }
}

.challenge {
  position: relative;
  text-align: center;
  margin: 20px;

  h4 {
    text-transform: lowercase;
  }
}

.challengeImage {
  height: 150px;
  width: 200px;
  position: absolute;
  margin-left: 80px;
  border-radius: 0.15rem;
  cursor: pointer;
}

.explore-radio {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  .radio {
    cursor: pointer;
    height: 40px;
    width: 40px;
  }
}


.searchField-container{
  // position: relative
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 20px 10px 30px;
  .zoom {
    position: absolute;
    left: 42px;
    z-index: 1500;
    width: 35px;
    top: -8px;
    bottom: auto;
  }
}

.line-seperator {
  margin: 40px 10px;
}

.searchField {
  background: #ffffff url("../../assets/images/explore/Layer2.png") no-repeat
    center center !important;
  position: relative;
  margin-left: 50px;
  margin-top: 20px;
  min-width: 80%;
  height: 50px;
  border: 0;
}

.dot-selected{
  -webkit-animation: pulse 0.8s ease-in infinite;
  animation: pulse 0.8s ease-in infinite;
}
