@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.closeLightbox {
  &.closeLightbox {
    position: fixed;
    // had to place fix due to modal
    right: 33px;
    top: 26px;
    background: inline-svg('<?xml version="1.0" encoding="UTF-8"?> <svg version="1.1" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"> <title>message close</title> <desc>Created with Sketch.</desc> <g fill="none" fill-rule="evenodd" stroke-linecap="square"> <g transform="translate(-278 -19)" stroke="#000" stroke-width="2"> <g transform="translate(-14 -5)"> <g transform="translate(292.82 25.106)"> <path d="m0.66667 0.47715 18.856 18.856"/> <path d="m0.66667 19.333 18.856-18.856"/> </g> </g> </g> </g> </svg>');
    padding: 0;
    border-radius: 100%;
    min-width: 1px;
    z-index: 100;
    width: 50px;
    height: 50px;
    background-size: 21px 21px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid transparent;

    &:hover {
      border-color: black;
      background-color: transparent;
    }

    @include mq("sm", max) {
      right: 15px;
      top: 15px;
      width: 24px;
      position: absolute;
    }
  }
}

.scrollContainerVCModal {
  min-height: 407px;

  @include mq("md", max) {
    min-height: 240px;
  }

  @include mq("sm", max) {
    min-height: 180px;
  }
}

.carouselScrollHorizontalTrackVCModal {
  .track {
    background: white !important;
    // important due to inline style from library
    height: 7px !important;
    opacity: 1;

    &.trackY {
      opacity: 0;
    }
  }

  .thumb {
    background: $blueGrey2 !important;
  }
}

.slides {
  width: 540px;
  position: relative;
  height: 404px;
  transition: 0.3s all;

  @include mq("md", max) {
    width: 280px;
    height: 300px;
  }

  @include mq("sm", max) {
    width: 150px;
    height: 180px;
  }
}

.sliderImageVCModal {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include mq("md", max) {
    height: 240px;
    width: 280px;
  }

  @include mq("sm", max) {
    height: 110px;
    width: 149px;
  }
}