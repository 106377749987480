@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.portfolioList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -18px;
  margin-right: -18px;
  li {
    position: relative;
    width: 50%;
		padding: 16px 18px;
    .portfolio {
      position: relative;
      border: 1px solid transparent;
      background-color: white;
      &.active {
        border-color: $gray;
        box-shadow: 0 2px 4px 0 rgba(black, 0.5);
			}
			> a {
				min-height: 100px;
				cursor: pointer;
				display: block;
			}
    }
    img {
      display: block;
      width: 100%;
    }
    .likeButton {
      display: inline-flex;
      align-items: flex-start;
      line-height: 1;
      background-color: white;
      position: absolute;
      right: -10px;
      bottom: -6px;
      z-index: 1;
      padding: 4px 8px;
      border: 0;
      font-size: 16px;
      .likeIcon {
        margin-right: 0;
        margin-left: 6px;
        max-width: 16px;
      }
    }
  }
}
