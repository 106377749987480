@import "../../assets/styles/variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";
.profileLevelWrap {
  display: inline-block;
  .profileLevel {
    display: inline-flex;
    flex-wrap: wrap;
    .progressBar {
      width: 76px;
      height: 6px;
      margin: 0 3px;
      border-radius: 2px;
      background-color: $gray;
      overflow: hidden;
      .bar {
        height: 6px;
        background-color: $yellow;
        border-radius: 2px;
      }
    }
  }
}
