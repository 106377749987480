
.btn-slide {
    border: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 25px;
      height: 25px;
      pointer-events: none;
    }
  }
  
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
.disable-btn{
  opacity: 0.1;
  cursor: default;
}