/* Global style starts here  */
body {
  font-family: $defaultFamily;
  overflow: auto;
  color: black;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

* {
  outline: none;
}

input {
  /* fix for IOS */
  appearance: none;
  border: solid 1px $gray;
  border-radius: 0;
  box-shadow: none;
  color: $darkGray1;
  display: block;
  font-size: 13px;
  height: 29px;
  letter-spacing: 0.46px;
  margin: 0 auto 22px;
  padding: 0 15px;
  text-transform: initial;
  width: 250px;
}

::placeholder {
  color: $greyWhite3;
  font-family: $defaultFamily;
  text-transform: lowercase;
}

.responseMsg {
  &:not(input) {
    color: black;
    font-size: 13px;
    letter-spacing: 0.7px;
    line-height: normal;
    margin: 10px auto 10px;
    padding: 8px 24px;
    position: relative;
    width: 250px;
  }

  .close {
    width: auto;
    height: auto;
    max-width: 24px;
    min-width: 0;
    min-height: 0;
    padding: 8px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border-radius: 0;
    font-size: 10px;
    font-weight: bold;
    color: black;
  }
}

.error {
  &:not(input) {
    background-color: rgba($beige, 0.7);
  }
}

.success {
  &:not(input) {
    background: rgba($green3, 0.14);
  }
}

.customCheckbox {
  svg {
    fill: $gray;
  }
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

/* Global style ends here  */

.btnEdit {
  background: white;
  border: 0;
  height: auto;
  padding: 0;
  width: 34px;

  >span {
    display: block;
    margin: 0 auto;
    text-align: center;
    // width: 34px;
  }

  &:hover {
    background: inherit;
  }
}

.footerLinks {
  button {
    height: auto;
    line-height: normal;
    margin-bottom: 5px;
    min-height: 1px;
    padding: 0;

    span {
      display: block;
    }
  }
}

.checkboxLabel {
  span {
    &:last-child {
      color: $slateGrey;
      font-size: 13px;
    }
  }
}

// custom modals
// no backgdrops
.likeListWrapper {
  overflow: auto;
  height: 100%;

  &.customModal {
    .dialogContent {
      overflow: visible;
      background: white;
      border: 1px solid #000;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      padding: 40px 55px 40px;
    }
  }

  div {
    &[role="document"] {
      background: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
      max-width: 591px;
      width: 100%;
      overflow: visible;
      margin: 0 auto;
      padding: 0;

      @include mq("sm", max) {
        width: auto;
        height: 100%;
        padding: 30px 16px 0;
        background: white;
        max-height: 100%;
      }
    }
  }

  .modalTitle {
    font-size: 24px;
    color: $darkGray;
    letter-spacing: 0.1px;
    margin: 0 0 32px;
    padding: 0;
    text-align: center;
  }

  .closeBtn {
    // Format the SVG as a URL
    background: inline-svg('<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 49.3 (51167) - http://www.bohemiancoding.com/sketch --> <title>message close</title> <desc>Created with Sketch.</desc> <defs></defs> <g id="Mocks-(main)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"> <g id="Logged-in-menu-expanded" transform="translate(-278.000000, -19.000000)" stroke="black" stroke-width="2"> <g id="open-menu" transform="translate(-14.000000, -5.000000)"> <g id="message-close" transform="translate(292.818187, 25.106457)"> <path d="M0.666666667,0.477152502 L19.5228475,19.3333333" id="Line"></path> <path d="M0.666666667,19.3333333 L19.5228475,0.477152502" id="Line-Copy-6"></path> </g> </g> </g> </g> </svg>'
      );
    width: 20px;
    height: 20px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0;
    min-width: 1px;
    min-height: 1px;

    &:hover {
      background-color: transparent;
    }
  }
}

.customModal {
  div {

    // had to place div to overwrite
    &.dialogContent {
      padding: 0;
    }
  }
}

.noBackdrop {
  >div {
    &:first-child {
      background: none;
    }
  }
}

// no backdrops ends here
// custom modals ends here

/* React feed carousel lightbox fix, overwriting default plugin css	*/
.footer_60scne {
  &.footer_60scne {
    height: auto;
    padding-top: 0;
  }
}

.slick-track {
  margin-left: inherit;
}

// user profile leves
.profileLevelContainer {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.userLikeList {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    max-width: 171px;
    padding: 5px;
    border-radius: 4px;
    background-color: #faf4ec;
    margin-bottom: 21px;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.1px;

    @include mq('sm', max) {
      max-width: 45%;
    }
  }

  @include mq('sm', max) {
    max-height: 85vh;
  }

  .likeUserAvatar {
    width: 38px;
    height: 38px;
  }

  .likeUserName {
    font-size: 16px;
    color: black;
    padding-left: 18px;
    padding-right: 0;

    span {
      @extend %textTruncate;
      max-width: 95px;
      display: block;
    }
  }
}

// buttons -------- >> starts
.btn {
  &.btnGreen {
    background-color: $green4;
    color: black;
    font-weight: bold;

    &:hover {
      background-color: darken($green4, 15%);
    }
  }
}

.btnSecondary {
  width: 53px;
  height: 20px;
  border-radius: 2px;
  background-color: $blueGrey2;
  font-size: 10px;
  color: black;
  padding: 0;
  min-width: 1px;
  text-transform: inherit;
  margin-left: 10px;
  min-height: 1px;

  &:hover {
    &.btnSecondary {
      background: transparent;
    }
  }
}

// ------ button ends here //

// ----- TOOOLLTIPPPYY -->>>
.dadaTippy-theme {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;

  &.tippy-tooltip {
    color: black;

    &[x-circle] {
      background-color: transparent;
    }
  }

  .tooltip {
    border-radius: 2px;
    background-color: $whiteTwo;
    border: solid 1px black;
    padding: 0;
    transform: translate(0, -15px);
    min-width: 90px;

    &:after,
    &:before {
      width: 0;
      height: 0;
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      // to create down arrow with border
    }

    &:after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $whiteTwo;
      bottom: -10px;
    }

    &:before {
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 11px solid black;
      bottom: -11px;
    }

    .tooltipList {
      padding: 12px 28px 8px 12px;
      font-size: 12px;
      cursor: pointer;

      .icon {
        margin-right: 8px;
        width: 18px;
      }

      p {
        margin: 0;
      }

      .link {
        background-color: white;
        border: solid 1px black;
        padding-left: 8px;
        font-size: 12px;
        height: 18px;
        line-height: normal;
        letter-spacing: 0.43px;
        width: 129px;
        @extend %textTruncate;
      }
    }
  }
}

// ----- TOOOLLTIPPPYY ENDS HEREEE -->>>

.closeTooltip {
  padding: 0;
  margin: 0;
  min-width: 1px;
  min-height: 1px;
  position: absolute;
  right: 8px;
  top: -2px;
}

// ----- TOOLTIP ends here /->

.branchingActive {
  width: 90px;
  display: flex;

  .countPerson {
    display: flex;
    flex-direction: column;

    .myBranchReply {
      display: block;
      height: 20px;
      width: 15px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }

  .branchingImage {
    width: 60px;
    height: 45px;
    background-size: cover;
    background-position: center center;
    background-color: $beige3;
    border: 2px solid $gray;
  }

  .branchNumber {
    color: $tundora2;
    font-size: 14px;
    background: $gray;
    font-weight: bold;
    display: inline-block;
    padding: 0 5px;
    text-align: center;

    &.top {
      margin-top: 16px;
    }
  }

  .simpleTooltip-them {
    border-radius: 2px;
    background-color: $whiteTwo;
    border: solid 1px black;
    padding: 0;
    display: block;
    min-width: 90px;
  }

  .branchNodeTooltip {
    border-radius: 2px;
    background-color: $whiteTwo;
    border: solid 1px black;
    padding: 0;
    display: block;
    transform: translate(-93px, -28px);
    min-width: 90px;

    &:after,
    &:before {
      width: 0;
      display: none;
      height: 0;
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      // to create down arrow with border
    }

    &:after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $whiteTwo;
      bottom: -10px;
    }

    &:before {
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 11px solid black;
      bottom: -11px;
    }
  }
}

.hasProgress {
  position: relative;

  &.btn:disabled {
    color: transparent;
  }

  .CircularProgress {
    line-height: 0;
    @include centered-element(50%, 50%, -50%, -50%);
  }
}

.materialModalScrollFix {
  padding-right: 0 !important;

  >div[aria-hidden] {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .overlay-modal {
    background-color: transparent;
  }

  .content {
    >div {
      justify-content: center;
    }
  }
  .ScrollbarsCustom {
    .content {
      >div {
        justify-content: start;
      }
    }
  }

  .vhAlignCenter {
    >div {
      background-color: transparent;
    }
  }
}

.btnReset {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;

  &:hover {
    background: none;
    outline: 0;
  }
}

.mainWrapper {
  header {
    padding: 35px 10px 15px 10px;
    left: 0;
    right: auto;
    height: auto;
    width: auto;
    background: transparent;
  }
}

.wrapper {
  margin-right: 0px !important;
}