@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.customInviteFriends {
  .customInviteChips {
    flex-direction: row-reverse;
    background-color: $beige;
    border-radius: 0;
    padding: 8px;
    height: auto;
    margin-right: 8px;
    span {
      padding: 0;
    }
    .removeChips {
      width: 8px;
      height: 8px;
      margin: 0;
      background-image: inline-svg(
        '<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g transform="translate(-278.000000, -19.000000)" stroke="black" stroke-width="2"><g transform="translate(-14.000000, -5.000000)"><g transform="translate(292.818187, 25.106457)"><path d="M0.666666667,0.477152502 L19.5228475,19.3333333"></path><path d="M0.666666667,19.3333333 L19.5228475,0.477152502"></path></g></g></g></g></svg>'
      );
      background-size: cover;
      background-repeat: no-repeat;
    }
    .chipsAvatar {
      margin: 0 0 0 10px;
      width: 20px;
      height: 20px;
      border: 1px solid black;
    }
  }
}
.inviteFriendsToolip {
  background: $beige;
  font-size: 16px;
  color: $darkGray;
  padding: 10px;
}
