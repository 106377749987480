@import "../../assets/styles/variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.badgeDot {
  width: 8px;
  height: 8px;
  display: block;
  margin: 3px 3px;
  border-radius: 50%;
}
.orangeDot {
  width: 12px;
  height: 12px;
}