@import "../../../assets/styles/mixins";

.newsContainer {
  width: 100%;
  height: auto;
  margin-top: 35px;
  border-top: 3px solid;
  display: flex;

  @include mq("xl", min) {
    height: 650px;
    max-height: 650px;
  }


  .newsHeader {
    display: flex;
    justify-content: flex-end;
  }
  .dadaContent-container {
    @include mq("sm", max) {
      margin-top: 0;
    }
  }
}

