@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.commentsWrapper {
  .commentsScroll {
    height: 230px;
    overflow: auto;
  }

  .profileName {
    font-size: 16px;
    color: $tundora2;
    @extend %textTruncate;
    max-width: 80px;
  }
  .SingleComment {
    border-bottom: 1px solid $gray;
    padding: 9px 8px;
    .likeIcon {
      width: 13px;
      height: 12px;
      margin: 0 1px 0 8px;

    }
    .profileAvatar {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }
    .profileWrapper {
      padding: 5px 0;
    }
    .profileMessage {
      color: $slateGrey;
      margin: 5px 0;
      font-size: 16px;
      word-wrap: break-word;
    }
  }
  .commentsHeader {
    border-bottom: 1px solid $gray;
  }
  .profileFooter {
    color: $blueGray3;
    font-size: 10px;
    padding-left: 12px;
  }
  .commentsFooter {
    padding: 16px 0 8px;
    .setWidth {
      min-width: 172px;
      position: relative;
    }
    .btnSecondary {
      width: 53px;
      height: 24px;
      border-radius: 2px;
      background-color: $tundora3;
      font-weight: bold;
      font-size: 16px;
      color: black;
      padding: 0;
      min-width: 1px;
      text-transform: inherit;
      margin-left: 10px;
      min-height: 1px;
    }
  }
  .placeComment {
    border: none;
    font-size: 16px;
    height: 24px;
    margin-bottom: 0;
    width: 100%;
    max-width: 180px;
    background: $gray;
    text-align: center;
    color: $tundora2;
  }
  .commentIcon{
    background: inline-svg('<?xml version="1.0" encoding="UTF-8"?> <svg version="1.1" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg"> <title>comments</title> <desc>Created with Sketch.</desc> <g fill="none" fill-rule="evenodd" stroke-linecap="square"> <g transform="translate(-124 -414)" stroke="#000"> <g transform="translate(0 .5)"> <g transform="translate(13 404.5)"> <g transform="translate(.48276 2.1255)"> <g transform="translate(110.92 7.7984)"> <g transform="translate(.61765)" stroke-width="2"> <path d="m3.613 13.65v3.8295l3.3232-2.129s4.9837 0.80294 7.4148-0.78999c2.6115-1.7111 2.957-2.3713 4.0794-4.1309 1.1225-1.7596-0.37884-7.4607-2.5083-8.6303-2.1295-1.1696-3.6841-1.2492-5.8943-1.3594-4.0462-0.20158-6.6445-0.16705-8.6154 3.1657-1.0047 1.6989-1.0677 4.7056-0.24634 6.8239 0.82138 2.1183 2.447 3.2204 2.447 3.2204z" fill="#fff"/> <path d="m4.6324 5.6822h7.0452 3.4733"/> <path d="m5.8676 9.3103s4.3284 0.25075 5.6639 0.25075c1.3355 0 2.3655-0.25075 2.3655-0.25075"/> </g> </g> </g> </g> </g> </g> </g> </svg>');
    width: 20px;
    height: 17px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: -10px;
    top: -5px;
  }
}
