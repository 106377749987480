@import "../../../assets/styles/mixins";

.faqsContainer {
  width: auto;
  height: auto;
  border-top: 3px solid;
  display: flex;
  position: relative;
  
  @include mq("xl", min) {
    height: 750px;
    max-height: 750px;
  }


  .newsHeader {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    bottom: auto;
  }
  h3 {
    line-height: 1.3;
  }
}
