@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.lineIcon {
  display: block;
  right: 19px;
  top: 50%;

  &,
  &:after,
  &:before {
    width: 12px;
    height: 1px;
    background: $pinkishGrey;
    position: absolute;
  }

  &:after,
  &:before {
    content: "";
  }

  &:after {
    top: 4px;
    right: 0;
  }

  &:before {
    bottom: 4px;
    right: 0;
  }
}

.customLevelWrap {
  margin-bottom: 20px;

  .levelBtn {
    width: 100%;
    height: 50px;
    // width: 178px;
    // border: solid 1px $pinkishGrey;
    color: $tundora2;
    background-color: white;
    min-width: 1px;
    line-height: 1.15;
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 40px;
    -moz-border-radius: 7px 5px 15px 12px / 9px 5px 7px 5px;
    -webkit-border-radius: 7px 5px 15px 12px / 9px 5px 7px 5px;
    border-radius: 7px 5px 15px 12px / 9px 5px 7px 5px;

    .selectedLevel {
      display: flex;
      align-items: center;
    }
  }
}

.LevelDropdown {
  .LevelDropdownContent {
    width: auto;
    min-width: 195px;
    max-width: 100%;
    background: white;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid $gray;
    // border-top: none;
    margin-top: 42px;
  }

  ul {
    padding: 0;

    li {
      font-size: 13px;
      height: 34px;
      line-height: 1.15;
      color: $pinkishGrey;
      letter-spacing: 0.45px;
      padding: 0;
      border-bottom: 1px solid $gray;
      padding-left: 25px;
      padding-right: 10px;

      &:last-child {
        border-bottom: none;
      }

      .lineIcon {
        display: none;
      }
    }
  }
}