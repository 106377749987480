@import "../../../assets/styles/_mixins";

.ourWorldviewContainer {
  position: relative;
  width: auto;
  height: auto;
  margin-top: 20px;
  border-top: 3px solid;
  display: flex;

  @include mq("xl", min) {
    height: 650px;
    max-height: 650px;
  }

  
  .newsHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
  }

  .ourWorldviewHeader {
    background-color: black;
    width: 250px;
    height: 30px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.5rem;
  }

  .description {
    p {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;

      @include mq("lg", min) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
      }
    }
  }
}
