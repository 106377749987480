@import "../../assets/styles/variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.userProfileLevel {
  @extend .list-unstyled;
  display: flex;
  justify-content: center;
  width: 72px;
  li {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: 6px 2px;
    &:nth-child(1) {
      background: $dotLevel1 ;
    }
    &:nth-child(2) {
      background: $dotLevel2;
    }
    &:nth-child(3) {
      background: $dotLevel3;
    }
  }
}
.userMaxLevel {
  @extend .list-unstyled;
  display: flex;
  justify-content: center;
  width: 72px;
  li {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: 6px 2px;
    background: $orange;
  } 
}
