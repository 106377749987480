@import "../../../assets/styles/_mixins";

.dadaInActionContainer {
  width: auto;
  height: auto;
  border-top: 3px solid;
  display: flex;
  position: relative;

  @include mq("xl", min) {
    height: 600px;
    max-height: 600px;
  }

  .newsHeader {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
  }
  .description {
    p {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;

      @include mq("lg", min) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
      }
    }
  }

  .dadaContent-container {
    border-bottom: 1px solid;
  }

  .dadaInActionImgWrap {

    .blog-image {
      height: 400px;

      @include mq("lg", min) {
        height: 100%;
    }

    @include mq("sm", max) {
      height: 100%;
  }
  }
    

    @include mq("lg", min) {
      display: flex;
      flex-direction: column;
    }
   }

  .dadaEditorialImg {
    position: relative;
    //height: 100%;
  }
}
