.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loader-container {
  height: 15px;
  width: 200px;
  display: flex;
  position: relative;
  .loader-dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: black;
    animation: move 700ms linear 0ms infinite;
    margin-right: 30px;

    &:first-child {
      position: absolute;
      top: 0;
      left: 0;
      animation: grow 700ms linear 0ms infinite;
    }

    &:last-child {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0;
      animation: grow 700ms linear 0s infinite reverse;
    }
  }
}

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes move {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(75px);
  }
}
