@import "../../assets/styles/_mixins";

.resp-wrap {
  flex-direction: row;
  max-width: 100%;

  @include mq("md", max) {
    flex-direction: column;
  }

  .w-40 {
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;


    @include mq("md", max) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .w-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;


    @include mq("md", max) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .w-60 {
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;


    @include mq("md", max) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

}

.editorialContainer {
  height: auto;
  width: auto;
  margin: 30px;
}

.detailsbox.read {
  span {
    &:not(.open-link-icon) {
      border-bottom: 1px solid #000;
    }
  }
}

.headingTag {
  background-color: black;
  width: 265px;
  height: auto;
  padding: 6px 20px;
  color: white;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5rem;

  @include mq("sm", max) {
    font-size: 16px;
    letter-spacing: 0.3rem;
  }
}

.blog-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left;
  object-position: left;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.dadaContent-container {
  width: 100%;
  height: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  margin-top: 20px;
  margin-bottom: 20px;

  @include mq("md", min) {
    flex: 1 1;
    order: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.dadaEditorialImg {
  flex: 1;
  position: relative;

  img {
    width: 100%;
    border: 4px solid #000;
    height: 100%;
    object-fit: contain;
    object-position: center;
    cursor: pointer;

    @include mq("sm", max) {
      border: 1px solid #000;
      object-fit: cover;
    }
  }

  [role="button"] {
    height: 100%;
  }
}

.content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;

  .read {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 20px;

    @include mq("lg", min) {
      margin-top: 0;
    }

    .open-link-icon {
      margin-left: 10px;
    }
  }
}

.blog-title {
  border-bottom: 1px solid;
  font-weight: 300;
  text-transform: lowercase;
  flex-basis: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 80px;
  line-height: 1;
  cursor: pointer;

  @include mq("xl", max) {
    font-size: 60px;
    line-height: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.description {
  text-align: left;
  font-size: 25px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0 0 0;
  line-height: 1.4;
  height: 210px;
  min-height: 210px;

  @include mq("lg", min) {
    font-size: 22px;
    line-height: 1.26;
    height: 240px;
    min-height: 240px;
  }

  @include mq("sm", max) {
    height: auto;
    max-height: 455px;
  }

  em {
    font-style: normal;
  }
}

.read {
  font-size: 22px;
  display: flex;
  align-items: center;
  font-weight: 300;

  .open-link-icon {
    margin-left: 5px;
    cursor: pointer;
  }

  >.content {
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  height: 100%;
  max-height: 100%;

  >div.author {
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-basis: 100%;
    padding-left: 15px;


    @include mq("md", min) {
      border-left: 0;
      padding-left: 25px;
      flex-basis: 22%;
      max-width: 22%;
    }

    &.pl-none {
      @include mq("md", min) {
        padding-left: 0;
      }
    }
  }

  >div.published {
    //flex-grow: 1;
    padding-left: 15px;
    display: flex;
    align-items: center;
    flex-basis: 100%;

    @include mq("md", min) {
      //   flex-basis: 280px;
      //   max-width: 280px;
      flex-basis: 33%;
      max-width: 33%;
    }

    span {
      display: block;
      font-weight: 500;

      @include mq("xl", max) {
        display: none;
      }
    }
  }

  .pagination-container {
    flex: 2 1 auto;

    @include mq("md", min) {
      //   flex-basis: 280px;
      //   max-width: 280px;
      flex: 0 0 45%;
      max-width: 45%;
    }
  }

  >div.read {
    justify-content: end;
    padding-right: 50px;
    display: flex;
    align-items: center;
    flex: 2 1 200px;

    @include mq("lg", min) {
      flex: 2 1 auto;
    }
  }
}

.details>div {
  text-align: left;
  border: 1px solid;
  font-size: 22px;
  padding: 10px 0;
  height: 100%;

  @include mq("lg", min) {
    flex: 2 1 auto;
    padding: 0;
  }
}

.d-flex {
  display: flex;
}

.d-wrap {
  display: flex;

  @include mq("md", max) {
    display: block;
  }
}

.justify-content-end {
  justify-content: flex-end;
}

.pl-mini {
  @include mq("md", min) {
    padding-left: 25px;
  }
}

.blogBody {
  >div {
    margin-bottom: 80px;
  }

  .resp-wrap {
    @include mq("lg", max) {
      height: auto;
      max-height: 100%;
    }
  }
}

.details-container {
  height: 80px;
  max-height: 80px;

  @include mq("lg", min) {
    height: 95px;
    max-height: 95px;
  }

  @include mq("md", max) {
    height: auto;
    max-height: 100%;
  }

}

.slick-initialized .slick-slide {
  display: inline-block !important;
  width: auto !important;
  left: 40px !important;
  position: relative;
}

.twitter-images {
  border: none;

  img {
    width: auto !important;
    margin: 0 10px;
  }
}