@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.likeButton {
  .likeIcon {
    display: inline-block;
    width: 20px;
    height: 17px;
    margin: 0 1px 0 4px;
    background-image: url(../../assets/images/heartIcon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    &.liked {
      background-image: url(../../assets/images/likedIcon.svg);
    }
  }
  &:hover {
    .likeIcon {
      background-image: url(../../assets/images/likedIcon.svg);
    }
  }
}