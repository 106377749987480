@import "../../assets/styles/_mixins";

.container-slider {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .btn-slide {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    transform: translateY(0);
    background-color: transparent;
    margin: 0 15px;
    img {
      width: 30px;

      @include mq("lg", min) {
        width: 55px;
      }

    } 
    &.prev{
      img {
        transform: rotate(0.5turn);
      }
    }
  }
}
// @media screen and (max-width: 700px){

// }


@include mq("sm", max) {
  .container-slider {
    margin: 10px 10px 0;
  }
}

.slide-count{
  //font-family: Mr Eaves Mod OT;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 55px;
  width: 80px;

  @include mq("lg", min) {
    font-size: 70px;
    width: 90px;
  }
  span {
    line-height: 1;
    position: relative;
    top: 4px;
    font-size: 45px;
    margin: 0 3px;

    @include mq("lg", min) {
      font-size: 55px;
      margin: 0 7px;
    }
  }
}
