@import "../../assets/styles/variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.interactions {
  bottom: 25px;
  opacity: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
  transition: 0.3s all;
  button {
    background: rgba(white, 0.9);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-width: 35px;
    width: 35px;
    padding: 0;
    height: 35px;
    min-height: 1px;
    font-size: 12px;
    color: $greyWhite3;
    border: none;
    img {
      width: 20px;
    }
  }
}
.replySlideWrapper {
  height: 100%;
}
.sliderImage {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &.replySlide {
    background-color: white;
    background-size: 24px 30px;
    border: none;
  }
  @include mq("md", max) {
    height: 240px;
    width: 280px;
  }
  @include mq("sm", max) {
    height: 110px;
    width: 149px;
  }
}
.carouselWrapper {
  max-width: 1198px;
}
.scrollBar {
  width: 100%;
}
.scrollContainer {
  min-height: 400px;
  @include mq("md", max) {
    min-height: 310px;
  }
  @include mq("sm", max) {
    min-height: 180px;
  }
}
.carouselScrollHorizontalTrack {
  .track {
    background: white !important;
    // important due to inline style from library
    height: 7px !important;
  }
  .thumb {
    background: $whiteThree !important;
  }
}
.slideContainer {
  width: 440px;
  position: relative;
  height: 300px;
  transition: 0.3s all;
  @include mq("md", max) {
    width: 300px;
    height: 300px;
  }
  @include mq("sm", max) {
    width: 150px;
    height: 180px;
  }
  .interactions {
    .replyBtn {
      max-width: 18px;
    }
    button[disabled] {
      opacity: 0.5;
    }
  }
  // .userProfileInfo,
  .userProfileLevel{
    opacity: 0;
    transition: 0.3s all;
  }
  &:hover {
    .interactions,
    // .userProfileInfo,
    .followIcon,
    .userProfileLevel{
      opacity: 1;
    }
    .bigAvatar{
      filter: grayscale(0);
    }
    .hearts{
      visibility: visible;
    }
  }
}

.likeArtWrap {
  position: relative;
  background: white;
}

.singleSlideItem {
  width: 60px;
  .slideContainer {
    width: 100%;
    .replySlide {
      height: 55px;
      width: 60px;
      &[disabled] {
        opacity: 0.5;
      }
    }
    @include mq("md", max) {
      .replySlide {
        width: 100%;
        height: 240px;
      }
    }
    @include mq("sm", max) {
      .replySlide {
        width: 100%;
        height: 110px;
      }
    }
  }
}

.duration {
  font-size: 13px;
  opacity: 1;
  padding-right: 8px;
  text-align: right;
  margin: 30px 0 0;
}

.pictureCarouselWrapper {
  .scrollContainer {
    margin-bottom: 8px;
    min-height: 450px;
  }

  .content-wrap {
    > div {
      -webkit-box-shadow: rgba(0,0,0,0.2) 0px 0px 20px 3px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 23px 1px;
      /*! padding-bottom: 15px; */
      background-color: white;
      margin: 16px 20px 16px 0;
    }

    .slideContainer {
      height: 100%;
    }
  }

  .ScrollbarsCustom {
    .slideContainer {
        padding: 0;
        display: block;
        box-shadow: 0 0px 25px 3px rgba(0, 0, 0, 0.08);
        padding-bottom: 15px;
        background-color: white;
        margin-right: 30px;
        transition: 0.5s all;
  
        &:hover {
          transition: 0.5s all;
          transform: scale(1.03);
        }
      }
  }
}
.customSlider {
  padding: 8px;
}
.row {
  display: flex;
  justify-content: center;
}
.avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
.lightboxImage {
  width: 800px;
}
.lightboxCaption {
  background: $whiteTwo;
  font-size: 13px;
  color: $darkGray;
  padding: 8px 0;
  & > div > div {
    flex: 1 0 0;
    min-width: 0;
    &.replyBtn {
      flex: 0 1 auto;
    }
  }
  .likeButton {
    .likeIcon {
      width: 30px;
      height: 27px;
    }
  }
  .shareForLightbox {
    width: 30px;
    height: 34px;
    margin-right: 20px;
    opacity: 1;
  }
  .lightboxReply {
    width: 30px;
    height: 28px;
  }
}
.followIcon {
  min-width: 25px;
}


.hearts{
  visibility: hidden;
}

.loader-wrapper{
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;

  .slideContainer {
    height: auto;
  }
}
