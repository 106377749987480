.custom-scoller {
  overflow-x: scroll;
  overflow-y: hidden;
}

.custom-scoller .items {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scroller-wrapper{
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;

  .slideContainer {
    height: auto;
  }
}


