@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/helper";

.blogPanel {
  > form {
    background-color: $whiteTwo;
    box-shadow: 0 0 1px $pinkishGrey;
  }
  .blogTitleName {
    font-size: 22px;
    margin: 0;
    border: 0;
    // border-bottom: 1px solid $gray;
    background: transparent;
    height: 60px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.1125px;
    color: black;
    text-align: center;
    padding: 5px 16px;
    width: 100%;
    @extend %textTruncate;
    &::placeholder {
      color: $tundora;
    }
  }
  .levelsInteractions {
    padding: 35px 25px 30px 30px;
  }
  .BlogbtnPost {
    height: auto;
    background-color: #cb1495;
    color: #fff;
    font-weight: 400;
    text-transform: lowercase;
    font-size: 26px;
    padding: 12px 0 0;
    width: auto;
    min-width: auto;
    border-radius: 2px 2px 0 0;
    &:hover {
      background-color: #DF177E;
      // background-color: darken($yellow1, 5%);
    }
    &.isReply {
      width: 80px;
      height: 34px;
      background-repeat: no-repeat;
      background-position: center 9px;
      background-image: inline-svg(
        '<svg width="52px" height="22px" viewBox="0 0 52 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-15.000000, -34.000000)"><g transform="translate(13.000000, 29.000000)"><g transform="translate(39.000000, 6.000000)" stroke-linecap="square"><path d="M5.70748299,1 L13.5986395,1" stroke="#{white}" stroke-width="2"></path><g transform="translate(7.719843, 10.954484) rotate(-13.000000) translate(-7.719843, -10.954484) translate(2.719843, 1.454484)"><path d="M8.18058085,0.462228157 C8.18058085,0.462228157 7.47123887,3.009307 6.91329195,5.01872415 C6.35534502,7.02814129 5.51862124,10.2807438 5.51862124,10.2807438 L6.91329195,11.1930724 L8.32487774,6.06071253 L9.76982304,1.25876398 L8.18058085,0.462228157 Z" stroke="#{white}" fill="#{white}"></path><path d="M5.23762704,10.2379455 C5.23762704,10.2379455 3.67415582,10.530533 2.89348593,11.9869179 C2.11281603,13.4433028 2.60032611,15.0857353 2.08710464,16.1154275 C1.57388317,17.1451197 0.134474176,17.522409 0.134474176,17.522409 C0.134474176,17.522409 1.12315813,18.6048879 3.14262698,17.5196901 C5.16209582,16.4344922 5.98201334,15.1600383 6.67725303,14.0065294 C7.37249271,12.8530204 7.02785359,11.0735052 7.02785359,11.0735052 L5.23762704,10.2379455 Z" stroke="#{$pink}" fill="#{$pink}"></path></g></g><text font-family="#{$defaultFamily}" font-size="20" font-weight="normal" letter-spacing="0.0661764741" fill="#{white}"><tspan x="0.983368496" y="19.791829">POS</tspan></text></g></g></g></svg>'
      );
    }

    > span {
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      font-weight: 300;
      line-height: 15px;
      padding: 3px 0;

      .blogProm {
        color: whitesmoke;
        display: block;
        font-size: 33px;
        font-weight: 500;
        text-transform: lowercase;
        letter-spacing: 1px;
  
        &::before {
          content: ' ';
          white-space: pre;
        }
        
        &::after {
          content: ' ';
          white-space: pre;
        }
      }
    }
    
  }

  .editModalBox {
    width: 400px;
  }
}
.error {
  display: flex;
  justify-content: center;
  color: red;
  font-size: 1rem;
  margin-top: 0.3rem;
}
.customChips {
  &.customChips {
    position: relative;
    margin-bottom: 30px;
    .customChipsInputField {
      width: 100%;
      display: flex;
      div[role="button"] {
        background: $beige;
        border-radius: 0;
        margin: 4px 4px 4px 6px;
        height: auto;
        position: relative;
        span {
          padding: 5px 5px 5px 18px;
          letter-spacing: 0.46px;
          display: block;
          max-width: 164px;
          @extend %textTruncate;
          &:empty {
            display: none;
          }
        }
        svg {
          font-size: 0;
          width: 8px;
          height: 8px;
          position: absolute;
          left: 15px;
          background-image: inline-svg(
            '<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g transform="translate(-278.000000, -19.000000)" stroke="black" stroke-width="2"><g transform="translate(-14.000000, -5.000000)"><g transform="translate(292.818187, 25.106457)"><path d="M0.666666667,0.477152502 L19.5228475,19.3333333"></path><path d="M0.666666667,19.3333333 L19.5228475,0.477152502"></path></g></g></g></g></svg>'
          );
          background-size: cover;
          background-repeat: no-repeat;
          path {
            opacity: 0;
          }
        }
      }
      input {
        width: 100%;
        max-width: 100%;
        // padding-left: 26px;
        color: $tundora2;
        font-size: 16px;
        padding-left: 5px;
      }
      > div {
        // border: solid 1px $pinkishGrey;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        max-height: 100px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5px 20px 5px 25px;
        -moz-border-radius: 7px 5px 15px 12px / 9px 5px 7px 5px;
        -webkit-border-radius: 7px 5px 15px 12px / 9px 5px 7px 5px;
        border-radius: 7px 5px 15px 12px / 9px 5px 7px 5px;
        &:before {
          background: transparent;
        }
        &:after {
          display: none;
        }
        ::placeholder {
          color: $slateGrey;
        }
      }
    }
    .addChipBtn {
      position: absolute;
      bottom: 14px;
      right: 15px;
      width: 20px;
      height: 20px;
      .addBtnImg {
        position: relative;
        &:before,
        &:after {
          background: $pinkishGrey;
          content: "";
          position: absolute;
        }
        &:before {
          position: relative;
          width: 15px;
          content: '';
          top: auto;
          left: 4px;
          background: url('../../assets/images/addition.svg') no-repeat center center;
            background-color: rgba(0, 0, 0, 0);
          background-size: cover;
          height: 15px;
          right: 0;
          bottom: auto;
          bottom: 0;
          display: block;
        }
        &:after {
          visibility: hidden;
        }
      }
    }
  }
}

.customAutoSuggestion {
  position: relative;
  z-index: 1200;
  width: 100%;
  max-width: 320px;

  .suggestionContainer {
    position: absolute;
    // top: 100%;
    top: 55px;
    left: 0;
    right: 0;
    cursor: default;
    max-height: 300px;
    overflow-x: hidden;
    border: 1px solid $greyWhite2;
    color: $darkGray;
    width: 273px;
    z-index: 1;
    .suggestionListing {
      padding: 0;
      margin: 0;
      .suggestionItem {
        list-style: none;
        > div > div {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
        }
        div {
          padding: 2px 0.4em;
          height: auto;
          font-weight: normal;
          .suggestedUserName {
            max-width: calc(100% - 100px);
          }
        }
        .avatar {
          img {
            max-width: 70px;
            width: 100%;
            background: white;
            box-shadow: -3px 3px 0px rgba(black, 0.06);
            object-fit: contain;
            border: 2px solid white;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.addImg {
  position: relative;
}


.blogCanvas {
  .customChipsInputField {
    > div {
      &::before {
        display: none;
      }
    }
  }

  .line-sep {
    position: relative;

    &::before {
      content: '';
      top: 0;
      left: 0;
      background: url("../../assets/images/line-sep.svg") no-repeat center center;
      background-size: inherit;
      width: 100%;
      display: block;
      position: absolute;
      height: 4px;
    }
  }


  .lineIcon {
    position: relative;
    width: 18px;
    content: '';
    top: auto;
    left: auto;
    background: url('../../assets/images/Nav-down.svg') no-repeat center center;
      background-color: rgba(0, 0, 0, 0);
    background-size: 100%;
    height: 18px;
    right: 0;
    bottom: auto;

    &::before, &::after {
      display: none;
    }
  }

  .levelBtn {
    padding-right: 15px;
  }

  .customLevelWrap {
    button {
      background-color: white;
      > span {
        display: flex;
        justify-content: space-between;
      }
    }
  } 

}
.blogBoxWrapper{
    background-color: whitesmoke;
}

.checkBox {
  font-size: 19px;
}

.blogBtn {
&::before , &::after{
  display: none;
}
}