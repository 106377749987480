.drawingSeed {
  // override
  .overlay-modal {
    position: static;
    .toolbox-area{
      background-color: transparent;
      position: relative;
    }
  }
  .toolbox-area {
    .toolbox-area-in {
      min-height: 100%;
    }
  }
  // .toolbox {
  //   .tool-icons {
  //     margin-top: 0;
  //     padding-top: 0;
  //     top: 45px;
  //     position: relative;
  //   }
  //   // Tools buttons
  //   .pencil {
  //     height: 45px;
  //   }
  //   .brush {
  //     height: 64px;
  //   }
  //   .brushTwo {
  //     height: 45px;
  //   }
  //   .highlight {
  //     height: 45px;
  //   }
  //   .eraser {
  //     height: 45px;
  //   }
  //   .goBack {
  //     height: 40px;
  //   }
  //   .goForward {
  //     height: 40px;
  //   }
  //   figure.toTrash {
  //     height: 40px;
  //   }
  // }
  // Drawing Box
  .content-base {
    margin: 0 60px;
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 800px;
    max-width: 800px;
  }

  .toolbox {
    &.custom-color {
      .clear-button {
        left: 5px;
        right: 5px;
        bottom: 22px;
      }
    }
  }

  .sketchpad-box {
    canvas {
      box-shadow: 0px 0 21px 0 rgba(0, 0, 0, 0.05);
  }
  }

}
.range-custom-style {
  .ui-widget-content{
    position: relative;
  }
}
ul#brush-size-list {
  margin: 6px 0 0 9px;
  width: 100%;
  padding: 0;
  li {
    text-align: -webkit-center;
    cursor: pointer;
  }
}


@keyframes pulse {
  25% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.0);
  }
}
@keyframes push {
  50% {
    transform: scale(0.8);
  }
}
@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}
.index-list {
  li {
    a {
      display: block;
      line-height: 0;
    }
  }
  .active-size {
    a {
      img {
        filter: invert(26%) sepia(100%) saturate(3447%) hue-rotate(223deg) brightness(104%) contrast(106%);
      }
    }

    svg {
        overflow: visible;
      path {
        fill: #1e5cff;
      }
    }
  }
}
li.active-size {
  a {
    display: block;
    line-height: 0;
    animation: pulse 0.8s ease-in infinite;
  }
}

.overlay-modal {
  .brush_size_slider {
    left: 100px;
    top: auto;
    bottom: 110px;
  }
}

.size-inner {
  position: absolute;
}

.color-shortcut {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  padding: 0 20px;
  top: 182px;
  z-index: 9999;
  
  &.color-shortcut-bg {
    top: 200px;
  }
  .color-button {
    height: 20px;
    width: 20px;
    border: 2px solid grey;
    border-radius: 50%;

    &.black {
      background-color: black;
    }

    &.white {
      background-color: white;
    }
  }
}

// .brush_size_slider {
//   display: none;
// }
