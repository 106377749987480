
.outside {
    position: relative;
    margin: 0 auto;
    width: 35px;
    top: 70px;
    left: -16px;
}

.drawingReply .outside {
    position: relative;
    margin: 0 auto;
    width: 35px;
    top: 55px;
    left: 7px;
    transform: rotate(90deg);
}