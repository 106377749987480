@import "../../assets/styles/_variables";
@import "../../assets/styles/_mixins";
@import "../../assets/styles/_helper";

.userexploreProfile {
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration: none;
  letter-spacing: 0.47px;
  min-width: 0;
  color: $tundora2;
  @include mq("xs", max) {
    font-size: 10px;
    color: $tundora2;
    flex-direction: column;
    align-items: baseline;
  }
  &:hover {
    .followIcon {
      opacity: 1;
    }
  }

  .userexploreProfileLevel {
    opacity: 1;
    .userProfileLevel {
      opacity: 1;
      width: auto;
    }
  }

  .userexploreProfileInfo {
    padding: 0 10px;
    .exploreProfileName {
      font-weight: 100;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }
    .exploreProfileLocation {
      margin-left: 8px;
      font-weight: 300;
      padding-right: 5px;
    }
  }
}
.exploreProfileName {
  position: relative;
  display: block;
  margin-top: 5px;
  //font-weight: lighter;
  letter-spacing: 1px;
  color: black;
  font-family: $defaultFamily;
  font-size: 20px;
  text-transform: lowercase;
  line-height: 20px;
}
.followIcon {
  width: 24px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s all;
  cursor: pointer;
}
.userexploreProfileInfo {
  padding-right: 30px;
  position: relative;
  min-width: 0;
  @extend %textTruncate;
  min-height: 30px;
  text-align: left;
}
.exploreProfileLevelContainer {
  .MuiAvatar-root-278{
    width: 35px;
    height: 35px;
    margin-top: 5px;
  }
  &:hover {
    .followIcon {
      opacity: 1;
    }
  }

  .exploreBigAvatar {
    width: 45px;
    height: 45px;
    margin: auto;
  }
}
.exploreProfileLocation{
  background-color: black;
  color: white;
  font-family: $defaultFamily;
  font-size: 15px;
  text-transform: lowercase;
  letter-spacing: 1px;
  line-height: 1;
  padding: 1px;
  margin-left: 10px;
  display: inline-block;
}

.exploreBigAvatar {
  width: 100px;
  height: 100px;
  filter: grayscale(100%);
}

.likeArtWrap {
  position: relative;
  background: white;
}


.content-wrap {
  display: flex;
  width: 100%;
  flex-direction: row;
}