// Colors
$orange: #f37200;
$blue: #3498db;
$blue2: #1E5CFF;
$green1: #005951;
$green2: #008e72;
$green3: #4caf50;
$green4: #9eff36;
$yellow: #f5a623;
$yellow1: #E4F800;
$pink: #FF0084;

$dotLevel1:#d62977;
$dotLevel2: #99cc34;
$dotLevel3:#4881df;
$dotLevel4:#f37200;

$darkGray: #1e1e1e;
$darkGray1: #333333;

$tundora: #4e4e4e;
$tundora2: #7E8282;
$tundora3: #dcdcdc;
$whiteTwo: #f5f5f5;
$whiteThree: #f3f3f3;
$pinkishGrey: #cacaca;
$pinkishGrey2: #c6c9c9;
$gray: #e5e5e5;
$greyWhite: #eeeeee;
$greyWhite2: #aaaaaa;
$greyWhite3: #a7a7aa;
$greyWhite4: #c8c8c8;
$blueGrey2: #d8d8d8;
$blueGray3: #acacac;
$slateGrey: #50535e;
$beige: #faf4ec;
$greyWhite4: #fafafa;
$beige3: #e9e9e9;
$beige2: #e8eced;
$french-gray: #ceced2;
$havlock-blue3: #46748d;
$havlock-blue4: #4b738e;

// Fonts
$defaultFamily: "mr-eaves-sans", serif;
