.preview {



  display: grid;

  /* grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 1fr; */

}

/* .preview div {
    flex: 1 0 30%;
    margin-top: 1px;
    margin-right: 1px;
    background: white;
    background-size: cover;
  
  } */

.previewimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gridImg {
  // height: 101px;
  // width: 134px;
  // background-position: center;
  // background-size: contain;
  // background-repeat: no-repeat;

  img{
    width: 100%;
    object-fit: contain;

    height: 100%;
  }
}
