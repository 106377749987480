
.dNone {
  display: none !important;
}
.dFlex {
  display: flex;
}
// .btnReset {
//   background-color: transparent;
//   margin: 0;
//   padding: 0;
//   border: none;
//   &:hover {
//     background: none;
//     outline: 0;
//   }
// }
.btnTransparent {
  background-color: transparent !important;
}
.vhAlignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vCenterSpaceBetween {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.zIndexLogin {
  z-index: 10000 !important;
}
.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}
.boxShadowNone {
  box-shadow: none;
}
.fullWidth {
  width: 100%;
}
// Margin
.m0 {
  margin: 0 !important;
}
.mb0,
.my0 {
  margin-bottom: 0 !important;
}
.mt0,
.my0 {
  margin-top: 0 !important;
}
// Padding
.p0 {
  padding: 0 !important;
}
.pb0,
.py0 {
  padding-bottom: 0 !important;
}
.pt0,
.py0 {
  padding-top: 0 !important;
}
