@import "../../../assets/styles/mixins";


.dadaAffairsContainer {
  width: auto;
  height: auto;
  border-top: 3px solid;

  @include mq("xl", min) {
    height: 770px;
    max-height: 770px;
  }


  .header {
    border-bottom: 2px solid;
    font-size: 60px;
    font-weight: 300;
    text-align: left;
    padding-left: 0;

    .title{
      padding-left: 0;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .content-container {
      width: 100%;
      display: grid;
      grid-template-rows: 2fr 1fr;
      text-align: left;
      word-wrap: break-word;

      .description {
        margin: 30px;
        font-size: 20px;
      }
    }

    img {
      margin-top: 20px;
      width: 100%;
      height: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      font-size: large;
      .read {
        display: flex;
        text-decoration: underline;
        .open-link-icon {
          margin-left: 5px;
          cursor: pointer;
        }
      }
      div {
        border: 1px solid;
      }
    }
  }
}

.dadaAffairsHeader {
  background-color: black;
  width: 250px;
  height: 30px;
  margin-left: 86.45%;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.5rem;
}
